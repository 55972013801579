import React, { useEffect, useState } from 'react'
import { ReactComponent as ErrorCircleIcon } from '../../icons/x-circle.svg'
import { ReactComponent as FBIcon } from '../../icons/fb.svg'
import { ReactComponent as WarningIcon } from '../../icons/warning-icon.svg'
import styles from './StoriesAndFeed.module.css'
import {
  CustomFieldInput,
  HostCustomFieldFragment,
  RegisteredParticipant,
  RegisterForCampaignMutation,
} from '../../generated/graphql'
import facebookSDK, { AuthResponse } from '../../facebook'
import sdk from '../../sdk'
import { ClientError } from 'graphql-request'
import ConfirmStoriesEmailAndCustomFields from './ConfirmStoriesEmailAndCustomFields'
import loadingAnimation from '../../icons/loading.gif'
import { CustomFieldValue } from '../CustomField'

type ApiStatus = 'success' | 'error' | null

export type ApiStoriesFeedStatusState = {
  status: ApiStatus
  fbUsers: RegisteredParticipant[] | null
}

type FBUserState = {
  userEmail: string | null
  userHandles: string | null
  customFieldValues: CustomFieldValue[] | null
}

const defaultFBUserState = {
  userEmail: null,
  userHandles: null,
  customFieldValues: null,
}

interface FeedAndStoriesProps {
  programHostLcId: string | null
  handleFbErrorState(): void
  handleFormComplete(data?: RegisterForCampaignMutation): void
  customFields?: HostCustomFieldFragment[] | null
}

const FACEBOOK_APP_ID = '234114167474524'

const fbConnectUrl =
  'https://loudcrowd.hubspotpagebuilder.com/connecting-a-facebook-page-to-an-instagram-creator-account-0'

const fbWhatHappenedUrl =
  'https://loudcrowd.hubspotpagebuilder.com/connecting-a-facebook-page-to-an-instagram-creator-account-0-0-0'

const storiesAndFeedCopy = (
  <div className={styles.storiesWarning}>
    <WarningIcon className={styles.warningIcon} />
    <div>
      {'If you '}
      <b>
        {'DO NOT yet have a Facebook Business Page connected with your Instagram Account, '}
        <a href={fbConnectUrl} className={styles.fbLink} target="_blank" rel="noreferrer">
          follow these steps
        </a>
      </b>
      {' (est. 3 minutes) or sign-up for “Feed Only”'}
    </div>
  </div>
)

const apiFBPermissionsErrorMsg = (
  <>
    {
      'We were unable to connect your Instagram account (likely because it is not connected to your Facebook Business Account. Please '
    }
    <a href={fbWhatHappenedUrl} className={styles.fbLink}>
      follow these steps
    </a>
    {' (est. 3 minutes) or sign-up for “Feed Only” with the simple form below.'}
  </>
)

const fbConnectionErrorMsg =
  'We were unable to connect to your Instagram account. Try again or sign up by completing the form below.'

const apiErrorMsg = 'Something went wrong when submitting ambassador program application, please try again.'

function FeedAndStories({
  programHostLcId,
  handleFbErrorState,
  handleFormComplete,
  customFields,
}: FeedAndStoriesProps): React.ReactElement {
  useEffect(() => {
    ;(async function () {
      try {
        const authResponse = await facebookSDK.init(FACEBOOK_APP_ID)
        if (authResponse.status === 'connected' && authResponse.authResponse.accessToken) {
          await sdk.FbUserRefresh({ token: authResponse.authResponse.accessToken })
        }
      } catch (e) {
        console.error(e)
      }
    })()
  }, [])

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fbUser, setFbUser] = useState<FBUserState>(defaultFBUserState)
  const [originalEmail, setOriginalEmail] = useState<string | null>(null)
  const [apiEmailError, setApiEmailError] = useState<boolean>(false)
  const [fbConnectionError, setFbConnectionError] = useState<boolean>(false)
  const [fbPermissionStatus, setFbPermissionsStatus] = useState<ApiStatus>(null)
  const [apiStatus, setApiStatus] = useState<ApiStatus>(null)
  const [authResponse, setAuthResponse] = useState<AuthResponse | null>(null)

  const updateSocial = async (isRefresh = false): Promise<unknown> => {
    try {
      const authResponse = await facebookSDK.authInstagramAccounts(isRefresh ? 'reauthorize' : undefined)
      if (authResponse.status !== 'connected') {
        setFbConnectionError(true)
        return
      } else {
        setAuthResponse(authResponse)
        setIsLoading(true)
        sdk
          .ValidateAndSaveFbUser({ fbToken: authResponse.token.value })
          .then((data) => {
            if (!data.validateAndSaveFbUser?.ok) {
              setApiStatus('success')
              setFbPermissionsStatus('error')
              handleFbErrorState()
            } else {
              setApiStatus('success')
              setFbPermissionsStatus('success')

              const usernames = data.validateAndSaveFbUser.usernames || []
              const userEmail = data.validateAndSaveFbUser.email
              if (userEmail && usernames.length > 0) {
                setFbUser({ ...fbUser, userEmail, userHandles: usernames.join(', ') })
                setOriginalEmail(userEmail)
              }
            }
          })
          .catch((error) => {
            console.log(error)
            if (
              error instanceof ClientError &&
              error.response.errors?.some((e) => e.extensions?.code === 'INSUFFICIENT_PERMISSIONS_GRANTED')
            ) {
              setFbPermissionsStatus('error')
              handleFbErrorState()
            }
            setApiStatus('error')
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    } catch (error) {
      console.log(error)
      setFbConnectionError(true)
    }
  }

  function handleSetEditing(): void {
    setIsEditing(true)
  }

  function handleUpdateEmail(email: string): void {
    setFbUser({ ...fbUser, userEmail: email })
  }

  function handleUpdateCustomFields(values: CustomFieldValue[]): void {
    setFbUser({ ...fbUser, customFieldValues: values })
  }

  function handleCloseApiError(): void {
    setApiStatus(null)
  }

  function handleCloseEmailError(): void {
    setApiEmailError(false)
  }

  function handleCompleteSignUp(): void {
    if (authResponse?.status !== 'connected') {
      setFbConnectionError(true)
      return
    } else if (programHostLcId && originalEmail && fbUser.userEmail) {
      sdk
        .RegisterForCampaign({
          data: {
            fbToken: authResponse?.token?.value,
            email: fbUser.userEmail,
            customFields: fbUser.customFieldValues?.map((v) => {
              return { id: v.id.toString(), value: v.value } as CustomFieldInput
            }),
          },
          lcId: programHostLcId || '',
        })
        .then((data) => {
          handleFormComplete(data)
        })
        .catch((error) => {
          console.error(error)
          setApiEmailError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const apiSuccess = apiStatus === 'success'
  const apiError = apiStatus === 'error'
  const apiDefault = apiStatus === null
  const fbPermissionsError = fbPermissionStatus === 'error'
  const isDefaultState = !isLoading && !fbConnectionError && apiDefault
  const isFbConnectSuccessState = !isLoading && apiSuccess && !fbPermissionsError
  const isFbConnectErrorState = !isLoading && fbConnectionError
  const isFbPermissionsErrorState = !isLoading && fbPermissionsError
  const isContainerErrorState = isFbConnectErrorState || isFbPermissionsErrorState || apiError

  const defaultStoriesState = () => (
    <>
      <div>{storiesAndFeedCopy}</div>
      <button className={styles.connectButton} onClick={() => updateSocial()}>
        <FBIcon className={styles.fbIcon} />
        Connect to Facebook
      </button>
    </>
  )

  const errorStoriesState = () => (
    <>
      <div className={styles.connectionError}>
        <div className={styles.connectionErrorIcon}>
          <ErrorCircleIcon width={20} height={20} onClick={handleCloseApiError} />
        </div>
        <div className={styles.connectionColumn}>
          <div className={styles.connectionErrorTitle}>Oops! That didn’t work</div>
          <div>
            {fbConnectionError && fbConnectionErrorMsg}
            {fbPermissionsError && apiFBPermissionsErrorMsg}
            {apiError && apiErrorMsg}
          </div>
        </div>
      </div>
      <button className={styles.connectButton} onClick={() => updateSocial()}>
        <FBIcon className={styles.fbIcon} />
        Connect to Facebook
      </button>
      <div className={styles.learnMoreLinkContainer}>
        <a href={fbWhatHappenedUrl} target="_blank" rel="noreferrer">
          What Happened?
        </a>
      </div>
    </>
  )

  const loadingState = () => (
    <div>
      <div className={styles.loadingContainer}>
        <img src={loadingAnimation} className={styles.animationGif} alt="loading" />
      </div>
    </div>
  )

  return (
    <>
      {isLoading && loadingState()}
      {isDefaultState && defaultStoriesState()}
      {isContainerErrorState && errorStoriesState()}
      {isFbConnectSuccessState && (
        <ConfirmStoriesEmailAndCustomFields
          apiEmailError={apiEmailError}
          isEditing={isEditing}
          userHandles={fbUser.userHandles}
          userEmail={fbUser.userEmail}
          handleSetEditing={handleSetEditing}
          handleCompleteSignUp={handleCompleteSignUp}
          handleUpdateEmail={handleUpdateEmail}
          handleCloseEmailError={handleCloseEmailError}
          customFields={customFields}
          handleUpdateCustomFields={handleUpdateCustomFields}
        />
      )}
    </>
  )
}

export default FeedAndStories
